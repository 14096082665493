<template>
  <section class="lista">
    <div class="quadro">
      <div class="conteudo">
        <div class="tela">
          <div class="icone">
            <Svgs nome="icones" />
          </div>
          <p>{{ totalDeIcones }} Ícones Unic</p>
        </div>

        <div class="select">
          <Svgs nome="setinha" />
          <select v-model="pastaSelecionada">
            <option value="todos">Todos</option>
            <option v-for="(icones, pasta) in iconesAgrupados" :key="pasta" :value="pasta">
              {{ mapaDeNomesDePastas[pasta] || pasta }}
            </option>
          </select>
        </div>

        <div class="input">
          <Svgs class="icone" nome="lupa" />
          <input type="text" spellcheck="false" placeholder="Buscar ícone" v-model="consulta" />
        </div>

        <button @click="storeModal.abrirModal('sugerirIcone', 'info', 'Sugerir novos ícones')">
          <Svgs nome="info" />
          <p>Sugerir Ícone</p>
        </button>
      </div>
    </div>

    <div class="aviso" v-if="state.carregando">
      <span></span>
    </div>

    <div class="icones">
      <div class="icone" v-for="(icone, indice) in iconesVisiveis" :key="indice" @click="abrirModal(icone)">
        <div class="tamanho">
          <div class="imagem">
            <div class="svg" :style="`--img-url: url('${icone.url}')`"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="carregar" v-if="iconesVisiveis.length < iconesFiltrados.length">
      <button @click="carregarMais">Carregar mais</button>
    </div>
  </section>
</template>

<script setup>
import { reactive, computed, ref, shallowReactive, watch, onMounted } from 'vue'
import { useStoreModal } from '@stores'
import Svgs from '@svgs'
import iconesJson from './svgs.json'

const storeModal = useStoreModal()

function abrirModal(icone) {
  storeModal.abrirModalEdicao('verIcone', 'check', 'Visualizar ícone', icone)
}

const state = reactive({
  icones: [],
  carregando: true
})

onMounted(() => {
  state.carregando = true
  state.icones = Object.entries(iconesJson).flatMap(([pasta, icones]) =>
    icones.map((icone) => ({
      ...icone,
      pasta
    }))
  )
  state.carregando = false
})

const mapaDeNomesDePastas = {
  '3d-arrows': 'Setas em 3D',
  'apple-logos': 'Logotipos do IOS',
  'basic-web': 'Ícones Básicos',
  'humans-3': 'Humanos',
  'isometric-alphabet': 'Alfabeto 3D',
  'objects': 'Objetos',
  'programming': 'Programação',
  'shopping': 'Compras',
  'smiley-and-people': 'Emojis',
  'social-logo': 'Logos de Redes Sociais',
  'social-websites': 'Websites Sociais',
  'ui-interface': 'Ícones de Interface',
  'nature': 'Natureza',
  'battery-and-power': 'Bateria e Energia',
  'check': 'Ícones de Check e X',
  'agriculture': 'Agricultura',
  'insects': 'Insetos',
  'places': 'Lugares',
  'speeches': 'Balões de Chat',
  'transport': 'Meios de transporte',
  '3d-shapes': 'Formas em 3D',
  'web-apps': 'Aplicativos Web'
}

const iconesBackup = ref([])
const iconesVisiveis = ref([])
const iconesAgrupados = shallowReactive({})
const limiteInicial = 100
const incremento = 100

onMounted(() => {
  state.carregando = true
  iconesBackup.value = Object.entries(iconesJson).flatMap(([pasta, icones]) =>
    icones.map((icone) => ({
      ...icone,
      pasta
    }))
  )
  atualizarAgrupados()
  iconesVisiveis.value = iconesBackup.value.slice(0, limiteInicial)
  state.carregando = false
})

const consulta = ref('')
const pastaSelecionada = ref('todos')
const consultaDebounce = ref('')

let debounceTimeout
watch(consulta, (novaConsulta) => {
  clearTimeout(debounceTimeout)
  debounceTimeout = setTimeout(() => {
    consultaDebounce.value = novaConsulta
    atualizarVisiveis()
  }, 300)
})

watch(pastaSelecionada, () => {
  atualizarVisiveis()
})

const iconesFiltrados = computed(() => {
  return iconesBackup.value.filter((icone) => {
    const consultaValida = !consultaDebounce.value || icone.nome.toLowerCase().includes(consultaDebounce.value.toLowerCase()) || (icone.tags && icone.tags.toLowerCase().includes(consultaDebounce.value.toLowerCase()))

    const pastaValida = pastaSelecionada.value === 'todos' || icone.pasta === pastaSelecionada.value

    return consultaValida && pastaValida
  })
})

function atualizarAgrupados() {
  const grupos = iconesBackup.value.reduce((grupos, icone) => {
    if (!grupos[icone.pasta]) {
      grupos[icone.pasta] = []
    }
    grupos[icone.pasta].push(icone)
    return grupos
  }, {})
  Object.assign(iconesAgrupados, grupos)
}

function atualizarVisiveis() {
  iconesVisiveis.value = iconesFiltrados.value.slice(0, limiteInicial)
}

function carregarMais() {
  const proximoLimite = iconesVisiveis.value.length + incremento
  iconesVisiveis.value = iconesFiltrados.value.slice(0, proximoLimite)
}

const totalDeIcones = computed(() => iconesBackup.value.length)
</script>

<style scoped>
section.lista {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 20px 0 0 0;
  min-height: 100dvh;
}

section.lista.minimizado {
  min-height: 500px;
  max-height: 500px;
  height: 500px;
  overflow-y: scroll;
  padding: 0 15px 20px 20px;
}

.free {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  width: 100%;
}

.free img {
  width: 100%;
  max-width: 50px;
  margin: 0 0 20px 0;
}

.free h3 {
  font-family: var(--bold);
  font-size: var(--f4);
  color: var(--cor-branco);
  text-align: center;
}

.free p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  text-align: center;
  line-height: 1.5;
  width: 100%;
  max-width: 400px;
  margin: 5px 0 10px 0;
}

.free button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  text-align: center;
  background-color: var(--cor-laranja);
  padding: 15px 30px;
  border-radius: 10px;
  transition: all 0.3s;
}

.free button:hover {
  background-color: var(--cor-laranja-escuro);
}

.free button svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
}

.aviso {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 20px 0 20px;
  width: 100%;
  position: relative;
  transition: fadeIn 0.3s linear;
}

.aviso span {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 3px solid var(--cor-azul);
  border-top: 3px solid transparent;
  animation: girando 1s linear infinite;
}

.aviso p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  text-align: center;
  width: 100%;
}

.quadro {
  display: flex;
  width: 100%;
  padding: 0 20px 20px 20px;
}

section.lista.minimizado .quadro {
  padding: 0 5px 0 0;
}

section.lista.minimizado .quadro .conteudo {
  flex-wrap: wrap;
  padding: 0;
}

.quadro .conteudo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: var(--cor-cinza-2);
  padding: 20px;
  border-radius: 10px;
}

section.lista.minimizado .quadro .tela {
  margin: 0;
  width: 100%;
}

section.lista.minimizado .quadro .tela .icone {
  width: 30px;
  height: 30px;
}

section.lista.minimizado .quadro .tela .icone svg {
  width: 14px;
  min-width: 14px;
}

section.lista.minimizado .quadro .tela p {
  font-size: var(--f2);
}

.quadro .tela {
  display: flex;
  align-items: center;
  margin: 0 30px 0 0;
}

.quadro .tela .icone {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-3);
  margin: 0 10px 0 0;
}

.quadro .tela .icone svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-branco);
}
.quadro .tela p {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
}

section.lista.minimizado .quadro .plano {
  display: none;
}

.quadro .plano {
  display: flex;
  align-items: center;
}

.quadro .plano img {
  width: 100%;
  max-width: 30px;
  max-height: 30px;
}

.quadro .plano .info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 200px;
  margin: 0 0 0 10px;
}

.quadro .plano .info .progresso {
  width: 100%;
  height: 3px;
  border-radius: 50px;
  background-color: var(--cor-cinza-3);
}

.quadro .plano .info .progresso .atual {
  width: 100%;
  height: 3px;
  border-radius: 50px;
  background-color: var(--cor-branco);
  transition: all 3s;
}

.quadro .plano .info p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  width: 100%;
  margin: 10px 0 0 0;
}

.quadro .plano .info p span {
  color: var(--cor-cinza-5);
}

section.lista.minimizado .quadro .input {
  max-width: calc(80% - 4px);
  margin: 0;
}

section.lista.minimizado .quadro .input input {
  padding: 10px 10px 10px 40px;
}

.quadro .input {
  max-width: 400px;
  margin: 0 15px;
}

.quadro .input svg {
  margin: 0;
}

.quadro .input input {
  background-color: var(--cor-cinza-3);
}

section.lista.minimizado .quadro .select {
  max-width: 100%;
  margin-left: 0;
  margin: 10px 0;
}

section.lista.minimizado .quadro .select select {
  padding: 10px;
}

.quadro .select {
  max-width: 200px;
  margin-left: auto;
}

.quadro .select select {
  background-color: var(--cor-cinza-3);
}

section.lista.minimizado .quadro button {
  padding: 10px 0;
  width: calc(20% - 4px);
}

section.lista.minimizado .quadro button svg {
  margin: 0;
}

section.lista.minimizado .quadro button p {
  display: none;
}

.quadro button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: var(--cor-azul);
  transition: all 0.3s;
}

.quadro button.upgrade {
  background-color: var(--cor-laranja);
}

.quadro button.upgrade:hover {
  background-color: var(--cor-laranja-escuro);
}

.quadro button:hover {
  background-color: var(--cor-azul-escuro);
}

.quadro button svg {
  width: 15px;
  min-width: 15px;
  margin: 0 10px 0 0;
  fill: var(--cor-branco-fixo);
}

.quadro button p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco-fixo);
}

section.lista.minimizado .icones {
  padding: 0;
}

section.lista.minimizado .icones .icone {
  width: calc(25% - 5px);
  margin: 10px 5px 0 0;
}

section.lista.minimizado .icones .icone .imagem {
  background-color: var(--cor-cinza-1);
}

section.lista.minimizado .icones .icone .imagem:hover {
  background-color: var(--cor-cinza-3);
}

section.lista.minimizado .icones .icone .imagem .svg {
  width: 30px;
}

.icones {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0 5px 0 20px;
  width: 100%;
  animation: fadeIn 0.3s ease;
}

.icones .icone {
  width: calc(10% - 15px);
  margin: 0 15px 20px 0;
  cursor: pointer;
}

.icones .icone .tamanho {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}

.icones .icone .tamanho .imagem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  transition: all 0.3s;
}

.icones .icone .tamanho .imagem:hover {
  background-color: var(--cor-cinza-3);
}

.icones .icone .tamanho .imagem .svg {
  width: 50px;
  height: 100%;
  background-color: var(--cor-branco);
  mask: var(--img-url) no-repeat center;
  mask-size: contain;
  -webkit-mask: var(--img-url) no-repeat center;
  -webkit-mask-size: contain;
  transition: all 0.3s;
}

.icones .icone .tamanho .imagem:hover .svg {
  transform: scale(1.05);
}

.icones .icone .tamanho .svg p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  margin: 10px 0 0 0;
}

.icones .icone .tamanho .svg svg {
  width: 35px;
  min-width: 35px;
  max-height: 35px;
  fill: var(--cor-branco);
  transition: all 0.3s;
}

.icones .icone .tamanho .svg:hover svg {
  transform: scale(1.1);
}

.carregar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 20px 0 30px 0;
}

.carregar button {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  background-color: var(--cor-cinza-3);
  border-radius: 10px;
  padding: 10px 20px;
  transition: all 0.3s;
}

.carregar button:hover {
  background-color: var(--cor-azul);
  color: var(--cor-branco-fixo);
}

/* Responsivo */
@media screen and (max-width: 1000px) {
  .quadro .conteudo {
    flex-wrap: wrap;
    padding: 15px;
  }

  .quadro .tela {
    margin: 0 0 10px 0;
    width: 100%;
  }

  .quadro .tela .icone svg {
    width: 16px;
    min-width: 16px;
  }

  .quadro .tela p {
    font-size: var(--f2);
  }

  .quadro .input {
    max-width: 60%;
    margin: 0 0 0 10px;
  }

  .quadro .select {
    max-width: calc(40% - 10px);
    margin-left: 0;
  }

  .quadro button {
    width: 100%;
    margin: 10px 0 0 0;
  }

  .icones {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0 10px 0 20px;
    width: 100%;
  }

  .icones .icone {
    width: calc(25% - 10px);
    margin: 0 10px 10px 0;
  }

  .icones .icone .tamanho .imagem .svg {
    width: 40px;
  }
}
</style>
