<template>
  <div id="sites">
    <ModalCriarDoZero />
    <ModalExcluirSite />
    <ModalDuplicar />
    <ModalOpcoes />
    <ModalPosicoes />
    <ModalLinkBio />

    <BalaoSuporte />
    <SectionCriar />
    <SectionAviso />
    <SectionLista />

    <Footer />
  </div>
</template>

<script setup>
import { onMounted, inject } from 'vue'
import { useStorePerfil, useStorePages } from '@stores'
import ModalCriarDoZero from './modals/ModalCriarDoZero.vue'
import ModalExcluirSite from './modals/ModalExcluirSite.vue'
import ModalLinkBio from '@/components/app/templates/modals/ModalLinkBio.vue'
import SectionCriar from './partials/SectionCriar.vue'
import SectionAviso from './partials/SectionAviso.vue'
import SectionLista from './partials/SectionLista.vue'
import Footer from '../../global/footer/Footer.vue'
import ModalDuplicar from './modals/ModalDuplicar.vue'
import ModalOpcoes from './modals/ModalOpcoes.vue'
import ModalPosicoes from './modals/ModalPosicoes.vue'
import BalaoSuporte from './partials/BalaoSuporte.vue'

const storePerfil = useStorePerfil()
const storePages = useStorePages()
const emitter = inject('emitter')

function carregarPerfil() {
  storePerfil.receberPerfilUsuario()
}

function carregarPages() {
  storePages.receberTodos()
}

onMounted(() => {
  carregarPerfil()
  carregarPages()
  emitter.on('atualizarPages', carregarPages)
})
</script>

<style scoped>
#sites {
  padding: 60px 0 0 0;
  animation: fadeIn 0.3s linear;
}
</style>
