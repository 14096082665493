<template>
  <Modal nome="verIcone" class="icones">
    <div class="conteudo">
      <div class="previa">
        <div class="fundoPng">
          <div class="icone" ref="elementoIcone" :style="iconeFundo" v-html="svgContent"></div>
        </div>
        <div class="copiando" v-if="state.loading === true">
          <span v-if="!state.urlIcone"></span>
          <p v-if="!state.urlIcone">Gerando link desse ícone, aguarde...</p>
          <div class="input" v-if="state.urlIcone">
            <Svgs class="icone" nome="link" />
            <input @click="copiarUrl(state.urlIcone)" v-model="state.urlIcone" placeholder="https://icone..." type="text" readonly />
          </div>
          <button @click="voltarUrl()" v-if="state.urlIcone">Gerar outro ícone</button>
        </div>
        <div class="opcoes" v-else>
          <div class="free" v-if="storePerfil.usuario?.assinatura?.tipo === 'free'">
            <p>Faça download em PNG, SVG ou subindo na CDN ao fazer seu upgrade na UnicPages</p>
            <button @click="router.push('/planos')">
              <Svgs nome="estrela" />
              Fazer Upgrade
            </button>
          </div>
          <div class="botoes" :class="{ carregando: state.loadingIcone }" v-else>
            <button @click="baixarSVG()">
              <Svgs nome="download" />
              <p>Baixar SVG</p>
            </button>
            <button @click="baixarPNG()">
              <Svgs nome="download" />
              <p>Baixar PNG</p>
            </button>
            <button @click="pegarLink()">
              <Svgs nome="link" />
              <p>Copiar URL</p>
            </button>
            <button class="usar" @click="usarIcone()" v-if="dentroCriacao">
              <Svgs nome="check" />
              <p>Usar ícone</p>
              <span></span>
            </button>
          </div>
        </div>
      </div>
      <div class="ajustes">
        <h3>Cor do ícone</h3>
        <ColorPicker :valor="state.corIcone" :onAtualizarCor="atualizarCorIcone" />
        <h3>Fundo do ícone</h3>
        <ColorPicker :valor="state.fundoIcone" :onAtualizarCor="atualizarFundoIcone" />
        <h3>Largura máxima</h3>
        <div class="tamanho">
          <input type="tel" v-model="state.tamanho" class="numero" v-mask="`###`" @focus="selecionarTexto" @input="() => atualizarTamanho(state.tamanho)" />
          <p>px</p>
          <input type="range" v-model="state.tamanho" min="0" max="600" class="mover" @input="() => atualizarTamanho(state.tamanho)" />
        </div>

        <h3>Rotação</h3>
        <div class="tamanho menor">
          <input type="tel" v-model="state.rotacao" class="numero" v-mask="`###`" @focus="selecionarTexto" @input="() => atualizarRotacao(state.rotacao)" />
          <p>deg</p>
          <input type="range" v-model="state.rotacao" min="0" max="360" class="mover" @input="() => atualizarRotacao(state.rotacao)" />
        </div>

        <h3>
          Arredondamento
          <button @click="zerarTudo">zerar</button>
        </h3>
        <div class="itens">
          <div class="item um">
            <Svgs nome="arredondamento" />
            <input type="tel" placeholder="0" spellCheck="false" @focus="selecionarTexto" v-mask="`####`" v-model="borderRadiusTop" />
          </div>
          <div class="item dois">
            <Svgs nome="arredondamento" />
            <input type="tel" placeholder="0" spellCheck="false" @focus="selecionarTexto" v-mask="`####`" v-model="borderRadiusRight" />
          </div>
          <div class="item tres">
            <Svgs nome="arredondamento" />
            <input type="tel" placeholder="0" spellCheck="false" @focus="selecionarTexto" v-mask="`####`" v-model="borderRadiusLeft" />
          </div>
          <div class="item quatro">
            <Svgs nome="arredondamento" />
            <input type="tel" placeholder="0" spellCheck="false" @focus="selecionarTexto" v-mask="`####`" v-model="borderRadiusBottom" />
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script setup>
import { inject, onMounted, reactive, computed, watch, ref } from 'vue'
import { useStoreModal, useStorePerfil, useStoreAjustes } from '@stores'
import Modal from '@components/global/modal/Modal.vue'
import ColorPicker from '@/components/global/elementos/ColorPicker.vue'
import ApiUpload from '@/api/upload/api-upload.js'
import { useRouter } from 'vue-router'
import Svgs from '@svgs'
import domtoimage from 'dom-to-image-more'

const props = defineProps(['selecao'])
const emitter = inject('emitter')
const storePerfil = useStorePerfil()
const storeModal = useStoreModal()
const router = useRouter()
const storeAjustes = useStoreAjustes()
const dadosModal = computed(() => storeModal.dadosDoModal('verIcone'))
const { iconeFundo } = estilos()

const elementoIcone = ref(null)
const icone = ref(null)
const svgContent = ref('')

const state = reactive({
  loading: false,
  urlIcone: '',
  icone: '',
  corIcone: '000000',
  corFundo: '00000000',
  tamanho: 300,
  rotacao: 0,
  borderRadiusTop: 0,
  borderRadiusBottom: 0,
  borderRadiusLeft: 0,
  borderRadiusRight: 0
})

const baixarSVG = () => {
  if (elementoIcone.value) {
    const svgElement = elementoIcone.value.querySelector('svg') || elementoIcone.value

    if (!svgElement || svgElement.tagName !== 'svg') {
      console.error('Nenhum elemento SVG encontrado.')
      return
    }

    const serializer = new XMLSerializer()
    const svgContent = serializer.serializeToString(svgElement)

    const blob = new Blob([svgContent], { type: 'image/svg+xml;charset=utf-8' })

    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = 'icone.svg'
    link.click()

    URL.revokeObjectURL(url)
  } else {
    console.error('Elemento SVG não encontrado.')
  }
}

const baixarPNG = () => {
  if (elementoIcone.value) {
    domtoimage
      .toPng(elementoIcone.value, {
        quality: 1,
        width: elementoIcone.value.offsetWidth,
        height: elementoIcone.value.offsetHeight,
        scale: 2,
        filter: (node) => {
          const isExternalStyle = node.tagName === 'LINK' || node.tagName === 'STYLE'
          return !isExternalStyle
        }
      })
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.href = dataUrl
        link.download = 'icone.png'
        link.click()
      })
      .catch((error) => {
        console.error('Erro ao capturar a imagem:', error)
      })
  }
}

const pegarLink = () => {
  if (elementoIcone.value) {
    state.loading = true
    domtoimage
      .toPng(elementoIcone.value, {
        quality: 1,
        width: elementoIcone.value.offsetWidth,
        height: elementoIcone.value.offsetHeight,
        scale: 2
      })
      .then((dataUrl) => {
        const byteString = atob(dataUrl.split(',')[1])
        const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0]
        const ab = new ArrayBuffer(byteString.length)
        const ia = new Uint8Array(ab)

        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i)
        }

        const blob = new Blob([ab], { type: mimeString })
        const arquivo = new File([blob], 'icone.png', { type: mimeString })

        const payload = {
          arquivo,
          maxWidth: 300
        }
        ApiUpload.uploadImagem(payload)
          .then((resp) => {
            state.urlIcone = resp?.url
            emitter.emit('mostrarAlerta', { tipo: 'positivo', mensagem: 'Imagem enviada com sucesso!' })
          })
          .catch(() => {
            state.loading = false
            emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Erro ao enviar a imagem' })
          })
      })
      .catch((error) => {
        console.error('Erro ao capturar a imagem:', error)
        emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Erro ao capturar a imagem' })
      })
  } else {
    console.error('Elemento não encontrado.')
    emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Elemento não encontrado.' })
  }
}

// Copiar url icone

function copiarUrl(url) {
  navigator.clipboard
    .writeText(url)
    .then(() => {
      emitter.emit('mostrarAlerta', { tipo: 'positivo', mensagem: 'Link copiado com sucesso!' })
    })
    .catch(() => {
      emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Erro ao copiar o link.' })
    })
}

// Voltar

function voltarUrl() {
  state.loading = false
  state.urlIcone = ''
}

function selecionarTexto(event) {
  setTimeout(() => {
    event.target.select()
  }, 0)
}

function atualizarFundoIcone(novaCor) {
  state.corFundo = novaCor
}

// Border Radius

const borderRadiusTop = computed({
  get() {
    return state.borderRadiusTop || 0
  },
  set(value) {
    state.borderRadiusTop = Number(value)
  }
})

const borderRadiusRight = computed({
  get() {
    return state.borderRadiusRight || 0
  },
  set(value) {
    state.borderRadiusRight = Number(value)
  }
})

const borderRadiusLeft = computed({
  get() {
    return state.borderRadiusLeft || 0
  },
  set(value) {
    state.borderRadiusLeft = Number(value)
  }
})

const borderRadiusBottom = computed({
  get() {
    return state.borderRadiusBottom || 0
  },
  set(value) {
    state.borderRadiusBottom = Number(value)
  }
})

function zerarTudo() {
  state.borderRadiusTop = 0
  state.borderRadiusRight = 0
  state.borderRadiusBottom = 0
  state.borderRadiusLeft = 0
}

// Estilos

function estilos() {
  const iconeSvg = computed(() => {
    const color = state.corIcone
    const tamanho = state.tamanho
    return {
      fill: `#${color}`,
      width: `${tamanho}px`,
      minWidth: `${tamanho}px`,
      maxHeight: `${tamanho}px`
    }
  })

  const iconeFundo = computed(() => {
    const color = state.corFundo
    return {
      backgroundColor: `#${color}`,
      borderRadius: `${state.borderRadiusTop}px ${state.borderRadiusRight}px ${state.borderRadiusBottom}px ${state.borderRadiusLeft}px`
    }
  })

  return { iconeFundo, iconeSvg }
}

function limparCampos() {
  state.icone = ''
}

async function pegarIcone() {
  if (dadosModal.value.id) {
    icone.value = dadosModal.value.id
  }
}

function atualizarCorIcone(novaCor) {
  state.corIcone = novaCor
  if (svgContent.value) {
    svgContent.value = svgContent.value.replace(/fill="[^"]*"/, `fill="#${novaCor}"`)
    svgContent.value = svgContent.value.replace(/style="[^"]*"/, `style="transform: rotate(${state.rotacao}deg); fill: #${novaCor}; width: ${state.tamanho}px; min-width: ${state.tamanho}px;"`)
  }
}

function atualizarTamanho(novoTamanho) {
  state.tamanho = novoTamanho
  if (svgContent.value) {
    svgContent.value = svgContent.value.replace(/width="[^"]*"/, `width="${novoTamanho}px"`)
    svgContent.value = svgContent.value.replace(/min-width="[^"]*"/, `min-width="${novoTamanho}px"`)
    svgContent.value = svgContent.value.replace(/style="[^"]*"/, `style="transform: rotate(${state.rotacao}deg); fill: #${state.corIcone}; width: ${novoTamanho}px; min-width: ${novoTamanho}px;"`)
  }
}

function atualizarRotacao(novaRotacao) {
  state.rotacao = novaRotacao
  if (svgContent.value) {
    svgContent.value = svgContent.value.replace(/style="[^"]*"/, `style="transform: rotate(${novaRotacao}deg); fill: #${state.corIcone}; width: ${state.tamanho}px; min-width: ${state.tamanho}px;"`)
  }
}

// Aplicar na criação

const dentroCriacao = computed(() => router.currentRoute.value.path.includes('/criar/'))

const usarIcone = () => {
  if (elementoIcone.value) {
    state.loadingIcone = true
    domtoimage
      .toPng(elementoIcone.value, {
        quality: 1,
        width: elementoIcone.value.offsetWidth,
        height: elementoIcone.value.offsetHeight,
        scale: 2
      })
      .then((dataUrl) => {
        const byteString = atob(dataUrl.split(',')[1])
        const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0]
        const ab = new ArrayBuffer(byteString.length)
        const ia = new Uint8Array(ab)

        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i)
        }

        const blob = new Blob([ab], { type: mimeString })
        const arquivo = new File([blob], 'icone.png', { type: mimeString })

        const payload = {
          arquivo,
          maxWidth: 300
        }
        ApiUpload.uploadImagem(payload)
          .then((resp) => {
            emitter.emit('aplicarIcone', resp?.url)
            emitter.emit('mostrarAlerta', { tipo: 'positivo', mensagem: 'Ícone aplicado com sucesso!' })
            state.loadingIcone = false
            storeModal.fecharModal('verIcone')
          })
          .catch((error) => {
            state.loadingIcone = false
            console.error('Erro ao aplicar ícone:', error)
            emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Erro ao aplicar ícone' })
          })
      })
      .catch((error) => {
        state.loadingIcone = false
        console.error('Erro ao capturar a imagem:', error)
        emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Erro ao capturar a imagem' })
      })
  } else {
    state.loadingIcone = false
    console.error('Elemento não encontrado.')
    emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Elemento não encontrado.' })
  }
}

watch(
  () => icone.value?.url,
  async (novaUrl) => {
    if (novaUrl) {
      try {
        const response = await fetch(novaUrl)
        if (!response.ok) {
          throw new Error(`Erro ao carregar SVG: ${response.statusText}`)
        }
        const svgText = await response.text()
        svgContent.value = svgText.replace('<svg', `<svg style="transform: rotate(${state.rotacao}deg); fill: #${state.corIcone}; width: ${state.tamanho}px; min-width: ${state.tamanho}px;"`)
      } catch (error) {
        console.error('Erro ao carregar SVG:', error)
      }
    }
  },
  { immediate: true }
)

onMounted(() => {
  watch(
    dadosModal,
    (novoValor) => {
      if (novoValor.id) {
        pegarIcone()
      }
    },
    { immediate: true }
  )
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
.conteudo {
  display: flex;
  width: 100%;
  padding: 30px;
}
.previa {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 60%;
  padding: 0 20px 0 0;
}
.previa .opcoes {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0 0 0;
}
.previa .opcoes .botoes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.previa .opcoes .botoes.carregando button.usar {
  opacity: 1;
}

.previa .opcoes .botoes button.usar span {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 3px solid var(--cor-branco-fixo);
  border-top: 3px solid transparent;
  position: absolute;
  animation: girando 0.6s linear infinite;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.3s;
}

.previa .opcoes .botoes.carregando button.usar span {
  opacity: 1;
  visibility: visible;
}

.previa .opcoes .botoes.carregando button.usar:hover {
  background-color: var(--cor-azul);
}

.previa .opcoes .botoes.carregando button.usar svg {
  fill: var(--cor-azul);
}

.previa .opcoes .botoes.carregando button.usar p {
  color: var(--cor-azul);
}

.previa .opcoes .botoes button.usar {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  padding: 15px 10px;
  background-color: var(--cor-azul);
  margin: 10px 0 0 0;
  position: relative;
  transition: all 0.3s;
}

.previa .opcoes .botoes button.usar:hover {
  background-color: var(--cor-azul-escuro);
}

.previa .opcoes .botoes button.usar svg {
  width: 15px;
  min-width: 15px;
  margin: 0 10px 0 0;
}

.previa .opcoes .botoes button.usar p {
  color: var(--cor-branco-fixo);
  font-size: var(--f2);
}

.previa .opcoes .botoes.carregando button {
  opacity: 0.3;
  pointer-events: none;
}

.previa .opcoes .botoes button {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(33.33% - 5px);
  background-color: var(--cor-cinza-3);
  border-radius: 10px;
  padding: 30px 0;
  transition: all 0.3s;
}
.previa .opcoes .botoes button:hover {
  background-color: var(--cor-azul);
}
.previa .opcoes .botoes button:hover svg {
  fill: var(--cor-branco-fixo);
}
.previa .opcoes .botoes button:hover p {
  color: var(--cor-branco-fixo);
}
.previa .opcoes .botoes button svg {
  width: 25px;
  min-width: 25px;
  max-height: 25px;
  fill: var(--cor-branco);
  margin: 0 0 15px 0;
  transition: all 0.3s;
}
.previa .opcoes .botoes button p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  transition: all 0.3s;
}
.previa .copiando {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 30px 0 0 0;
}
.previa .copiando span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 5px solid var(--cor-azul);
  border-top: 5px solid transparent;
  animation: girando 1s ease-in-out infinite;
}
.previa .copiando p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 0 10px;
}
.previa .copiando button {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  background-color: var(--cor-azul);
  padding: 10px 20px;
  border-radius: 50px;
  margin: 10px 0 0 0;
  transition: all 0.3s;
}
.previa .copiando button:hover {
  background-color: var(--cor-azul-escuro);
}
.previa .free {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 0 0 0;
}
.previa .free p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  text-align: center;
  line-height: 1.5;
}
.previa .free button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-laranja);
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco-fixo);
  padding: 8px 10px;
  border-radius: 5px;
  margin: 10px 0 0 0;
  transition: all 0.3s;
}
.previa .free button:hover {
  background-color: var(--cor-laranja-escuro);
}
.previa .free button svg {
  width: 10px;
  min-width: 10px;
  fill: var(--cor-branco-fixo);
  margin: 0 5px 0 0;
}
.fundoPng {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-image: url('https://arquivos.unicpages.com/imagens/app/png.png');
  width: 300px;
  min-width: 300px;
  height: 300px;
  min-height: 300px;
  background-color: var(--cor-cinza-1);
  overflow: hidden;
}
.fundoPng .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-4);
  width: 100%;
  height: 100%;
}
.fundoPng .icone svg {
  width: 80px;
  min-width: 80px;
  fill: var(--cor-branco);
}

.fundoPng svg.oculto {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.ajustes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid var(--cor-cinza-3);
  border-radius: 10px;
  width: 40%;
  padding: 0 20px 20px 20px;
}

h3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 20px 0 10px 0;
  width: 100%;
}

h3 button {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  background-color: var(--cor-cinza-3);
  border-radius: 50px;
  padding: 5px 10px;
  transition: all 0.3s;
}

h3 button:hover {
  color: var(--cor-branco);
  background-color: var(--cor-cinza-4);
}

.tamanho {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.tamanho p {
  position: absolute;
  left: 30px;
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  pointer-events: none;
}

input.numero {
  background-color: transparent;
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  width: 60px;
  padding: 0;
  transition: all 0.3s;
}

input.numero:focus {
  color: var(--cor-branco);
}

input.numero::-webkit-inner-spin-button,
input.numero::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input.mover {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 20px;
  background: var(--cor-cinza-3);
  border-radius: 50px;
  outline: none;
  transition: all 0.3s;
}

input.mover:hover::-webkit-slider-thumb {
  background: var(--cor-cinza-5);
}

input.mover:hover::-moz-range-thumb {
  background: var(--cor-cinza-5);
}

input.mover::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: var(--cor-branco);
  border: 3px solid var(--cor-cinza-3);
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s;
}

input.mover::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: var(--cor-branco);
  border: 3px solid var(--cor-cinza-3);
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s;
}

.itens {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.itens .item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(50% - 5px);
  padding: 0 0 10px 0;
  position: relative;
}

.itens .item input {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  padding: 15px 10px 15px 40px;
  width: 100%;
  background-color: var(--cor-cinza-3);
  border-radius: 10px;
  transition: all 0.3s;
}

.itens .item input:focus {
  background-color: var(--cor-cinza-4);
}

.itens .item input::placeholder {
  color: var(--cor-cinza-5);
}

.itens .item svg {
  position: absolute;
  left: 10px;
  width: 20px;
  min-width: 20px;
  fill: var(--cor-branco);
  pointer-events: none;
}

.itens .item.dois svg {
  transform: rotate(90deg);
}

.itens .item.tres svg {
  transform: rotate(270deg);
}

.itens .item.quatro svg {
  transform: rotate(180deg);
}
/* Responsivo */
@media screen and (max-width: 1024px) {
  .conteudo {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
  }

  .fundoPng {
    width: 250px;
    min-width: 250px;
    height: 250px;
    min-height: 250px;
  }

  .previa {
    width: 100%;
    padding: 0 0 20px 0;
  }

  .ajustes {
    width: 100%;
  }
}
</style>
