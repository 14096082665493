<template>
  <div id="balao" :class="{ mostrar: state.mostrarOpcoes }" ref="balaoSuporte">
    <button @click="state.mostrarOpcoes = !state.mostrarOpcoes">
      <Svgs nome="setinha" />
      <p>Suporte</p>
    </button>
    <div class="opcoes">
      <a href="https://api.whatsapp.com/send?phone=1831993895&text=Ol%C3%A1%2C%20preciso%20de%20ajuda%20na%20UnicPages" target="_blank">
        <Svgs nome="whatsapp" />
        <p>Suporte no Whatsapp</p>
      </a>
      <a href="https://discord.gg/GjqvZMsV9r" target="_blank">
        <Svgs nome="discord" />
        <p>Comunidade Discord</p>
      </a>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { helperClicouFora } from '@helpers'
import Svgs from '@svgs'

const balaoSuporte = ref(null)

const state = reactive({
  mostrarOpcoes: false
})

helperClicouFora.clicouFora([
  {
    alvo: balaoSuporte,
    retornoChamada: () => (state.mostrarOpcoes = false)
  }
])
</script>

<style scoped>
#balao {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10;
}

button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: var(--cor-verde);
  transition: all 0.3s;
}

#balao.mostrar button {
  background-color: var(--cor-azul);
}

#balao.mostrar button svg {
  transform: rotate(180deg);
}

button svg {
  width: 10px;
  min-width: 10px;
  fill: var(--cor-branco-fixo);
  transition: all 0.3s;
}

button p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco-fixo);
  margin: 0 0 0 10px;
}

.opcoes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  bottom: 40px;
  right: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  background-color: var(--cor-cinza-1);
  border: 1px solid var(--cor-cinza-3);
  padding: 20px;
  width: 220px;
  border-radius: 10px;
  transition: all 0.3s;
}

.opcoes a {
  display: flex;
  align-items: center;
  padding: 6px 0;
}

.opcoes a:hover p {
  color: var(--cor-azul);
}

.opcoes a:hover svg {
  fill: var(--cor-azul);
}

.opcoes a p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 0 10px;
  transition: all 0.3s;
}

.opcoes a svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
  transition: all 0.3s;
}

#balao.mostrar .opcoes {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
</style>
